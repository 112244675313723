import { createStore } from 'vuex';

export default createStore({
  state: {
    appLanguage: localStorage.getItem('appLanguage'),
  },
  getters: {
    getAppLanguage: (state) => state.appLanguage,
  },
  mutations: {
    setAppLanguage(state, language) {
      state.appLanguage = language;
      localStorage.setItem('appLanguage', language);
    },
  },
  actions: {
    setAppLanguage(context, language) {
      context.commit('setAppLanguage', language);
    },
  },
  modules: {},
});
