import { createApp } from 'vue';

import '@varlet/ui/es/style.js';
import '@varlet/touch-emulator';

import '@/css/main.scss';

import {
  Button, Ripple,
} from '@varlet/ui';

import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App);

app.use(store);
app.use(router);

app.use(Button).use(Ripple);

app.directive('click-outside', {
  mounted(el, binding) {
    // eslint-disable-next-line func-names
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

app.mount('#app');
